import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { API } from 'aws-amplify'
import SaveIcon from '@mui/icons-material/Save'
import LoadingButton from '@mui/lab/LoadingButton'
import { Box, Button, Grid, Stack, Typography } from '@mui/material'
import { MuiOtpInput } from 'mui-one-time-password-input'
import { useAuth } from '../../../contexts/AuthContext'
import { useLogo } from '../../../contexts/SponsorLogoContext'
import { useErrorToast } from '../../../hooks/useErrorToast'
import { SignInMfa } from './SignInMfa'
import { useTourContext } from '../../../contexts/TourContext'
import { useMenuConfig } from '../../../hooks/useMenuConfig'
import { ACCESS_LEVEL } from '../../../contstants/constants'
import { useDispatch } from 'react-redux'
import { clearStore } from '../../../store/dashboard-reducer/dashboard-reducer'
import { clearUserStore } from '../../../store/user-reducer/user-reducer'

export const MfaVerificationCodeLogin = (props) => {
  const [otp, setOtp] = useState('')
  const [fullOtp, setFullOtp] = useState('')
  const [isVerifyDisabled, setIsVerifyDisabled] = useState(true)
  const { storeUser, setUserAclData, userAclData, checkCurrentRedirectRouteAccess, storeAllUserGroups, setNoAclError, noAclError } = useAuth()
  const sidebarMenuList = useMenuConfig()
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)
  const { storeSponsorLogo } = useLogo()
  const [userGroup, setUserGroup] = useState('')
  const { setIsNewUser } = useTourContext()
  const dispatch = useDispatch()
  const [curPath, setCurrPath] = useState('/login')
  const [hasAclAccessRes, setHasAclAccessRes] = useState(false)
  const [hasSponsorLogoRes, setHasSponsorLogoRes] = useState(false)
  const [signInDetails, setSignInDetails] = useState(null)
  const [aclAccessApiLoading, setAclAccessApiLoading] = useState(true)
  const [logoApiLoading, setLogoApiLoading] = useState(true)
  const [userApiLoading, setUserApiLoading] = useState(true)
  const [aclFailError, setAclFailError] = useState('')
  const [userGroupsFailError, setUserGroupsFailError] = useState('')
  const [logoDetailsFailError, setLogoDetailsFailError] = useState('')

  const navigate = useNavigate()
  const { showError } = useErrorToast()

  const handleChange = (value) => {
    if (value?.length < 6 && !isVerifyDisabled) {
      setIsVerifyDisabled(true)
    }
    setOtp(value)
  }

  const handleComplete = (value) => {
    setIsVerifyDisabled(false)
    setFullOtp(value)
  }

  // store user data function (store user data only if all three apis are successful and signIn details is success)
  const storeUserDetails = (signInDetails, userGrp, aclGrp) => {
    storeUser({
      email: signInDetails?.value?.signInUserSession?.idToken?.payload?.email,
      name: signInDetails?.value?.signInUserSession?.idToken?.payload?.name,
      jwtToken: signInDetails?.value?.signInUserSession?.idToken?.jwtToken,
      sub: signInDetails?.value?.signInUserSession?.idToken?.payload?.sub,
      allowedGroups: signInDetails?.value?.signInUserSession?.idToken?.payload['cognito:groups'],
      currUserName: signInDetails?.value?.signInUserSession?.idToken?.payload['cognito:username'],
      ...{ ...userGrp ? { userGroup: userGrp } : {} },
      ...{ ...aclGrp ? { aclUserGrp: aclGrp } : {} }
    })
    if (signInDetails?.value?.signInUserSession?.idToken?.payload['custom:newClientUser'] && signInDetails?.value?.signInUserSession?.idToken?.payload['custom:newClientUser'] === 'true') {
      setIsNewUser(true)
    }
    window.localStorage.setItem('userGroup', userGrp)
    window.localStorage.setItem('aclUserGrp', aclGrp)
  }

  useEffect(() => {
    // store user data only if all three apis are successful and signIn details is success
    if (signInDetails?.type === 'SUCCESS' && userGroup && (userGroup === 'admin' ? true : hasAclAccessRes) && hasSponsorLogoRes && (userGroup === 'admin' ? true : userAclData && userAclData?.services?.length)) {
      let userGrp
      let aclGrp
      userGrp = userGroup
      if (userGrp !== 'pm' && userGrp !== 'admin') {
        aclGrp = userGrp
        userGrp = 'adv-classic'
      }
      storeUserDetails(signInDetails, userGrp, aclGrp)
    }

    // if all apis are successful and set all flags true and check below condition -
    // if all flags are true then only redirect to the specific route otherwise redirect to login
    if (userGroup && (userGroup === 'admin' ? true : hasAclAccessRes) && hasSponsorLogoRes && (userGroup === 'admin' ? true : userAclData?.services?.length)) {
      if (signInDetails?.type === 'SUCCESS') {
        let userGrp
        userGrp = userGroup
        if (userGrp !== 'pm' && userGrp !== 'admin') {
          userGrp = 'adv-classic'
        }
        if (userGrp === 'adv-classic' || userGrp === 'pm') {
          navigate(curPath)
        } else if (userGrp === 'admin') {
          navigate('/admin')
        }
      }
    }
  }, [userGroup, hasAclAccessRes, hasSponsorLogoRes, signInDetails, userAclData])

  // if api's proceed successfully and any apis fails redirect to login page and clear stored data
  useEffect(() => {
    if (!aclAccessApiLoading && !logoApiLoading && !userApiLoading && isButtonDisabled) {
      if (aclFailError || logoDetailsFailError || userGroupsFailError || noAclError) {
        if (!aclAccessApiLoading && aclFailError) {
          showError(aclFailError)
        } else if (!logoApiLoading && logoDetailsFailError) {
          showError(logoDetailsFailError)
        } else if (!userApiLoading && userGroupsFailError) {
          showError(userGroupsFailError)
        }
        storeUser(null)
        window.localStorage.clear()
        dispatch(clearStore('RESET'))
        props?.optProps?.handleBackToLogin()
        setIsButtonDisabled(false)
        setAclAccessApiLoading(true)
        setLogoApiLoading(true)
        setUserApiLoading(true)
        dispatch(clearUserStore('RESET'))
        setUserAclData({})
      }
    }
  }, [aclAccessApiLoading, logoApiLoading, userApiLoading, aclFailError, logoDetailsFailError, userGroupsFailError, isButtonDisabled, noAclError])

  // use stored api failed or no acl data errors
  useEffect(() => {
    if (userGroup && (noAclError || aclFailError)) {
      if (userGroup !== 'admin' && noAclError) {
        setAclAccessApiLoading(false)
        setHasAclAccessRes(false)
        Sentry.captureException('You don\'t have required access. Please contact administration')
      } else if (userGroup !== 'admin' && aclFailError) {
        setAclFailError('Failed to login. Please contact administration')
        setAclAccessApiLoading(false)
        setHasAclAccessRes(false)
        Sentry.captureException('Failed to login. Please contact administration')
      }
    }
  }, [noAclError, aclFailError, userGroup])

  // redirect to the first accessible path form ACL
  let prioritizedPath = '/login'
  const getCurrentRedirectionPath = (aclData) => {
    for (const data of sidebarMenuList) {
      // compare menuConfig with the ACL to get first accessible path
      const serviceExists = aclData?.services?.find(service => service?.Service === data?.moduleCd)
      if (serviceExists) {
        // menuConfig paths without subMenus
        if (data?.path) {
          prioritizedPath = data?.path ? data?.path : props?.optProps?.handleBackToLogin()
          break
          // menuConfig subMenu paths
        } else if (data?.paths && data?.paths?.length > 0) {
          const currPath = data?.paths?.filter((path) => checkCurrentRedirectRouteAccess(path?.path, aclData) === true)
          prioritizedPath = currPath?.length > 0 ? currPath[0]?.path : props?.optProps?.handleBackToLogin()
          break
        }
      }
    }
    setCurrPath(prioritizedPath)
    // set success flag to true if api is successful
    setHasAclAccessRes(true)
    setAclAccessApiLoading(false)
  }

  const fetchACLAccessApi = (props, retries) => {
    let hasError = false
    // const userGrp = window.localStorage.getItem('userGroup')
    API.get('baseAclURL', `user-access-control/v1/user/${props}`)
      .then((res) => {
        if (res && res.success && res?.data?.services?.length > 0) {
          getCurrentRedirectionPath(res.data)
          setUserAclData(res.data)
          setNoAclError(false)
          window.localStorage.setItem('user-acl-data', JSON.stringify(res.data))
        } else {
          if (userGroup && userGroup !== 'admin') {
            Sentry.captureException('You don\'t have required access. Please contact administration')
            setNoAclError(true)
            setAclAccessApiLoading(false)
            setHasAclAccessRes(false)
          } else if (!userGroup) {
            setNoAclError(true)
          }
        }
      })
      .catch((error) => {
        hasError = true
        setHasAclAccessRes(false)
        Sentry.captureException(error?.response?.data?.errorInfo?.userMessage || error)
      })
      .finally(() => {
        // retry api call 2 more times if first api call fails
        if (hasError && retries > 0) {
          retries -= 1
          setTimeout(() => {
            fetchACLAccessApi(props, retries) // retry api call if fails
          }, 100)
        } else if (hasError && retries === 0) {
          setAclAccessApiLoading(false)
          setHasAclAccessRes(false)
          if (userGroup && userGroup !== 'admin') {
            setAclFailError('Failed to login. Please contact administration')
            Sentry.captureException('Failed to login. Please contact administration')
          } else if (!userGroup) {
            setAclFailError('Failed to login. Please contact administration')
          }
        }
      })
  }

  const getLogoDetailsApi = (userId) => {
    API.get('baseSponserURL', `data-maintenance/v1/logo-details/${userId}`)
      .then(response => {
        if (response?.data) {
          if (Array.isArray(response.data) && response?.data?.length) {
            storeSponsorLogo(response.data[0])
            // set success flag to true if api is successful
            setHasSponsorLogoRes(true)
            const favicon = document.getElementById('favicon')
            if (favicon) {
              favicon.href = response.data[0].favIconUrl ? response.data[0].favIconUrl : './ArisFavicon.svg'
            }
            const title = document.getElementById('title')
            if (title && response.data[0].sponsorName) {
              title.innerText = response.data[0].sponsorName ? response.data[0].sponsorName : 'Aris Investing'
            }
          } else {
            const favicon = document.getElementById('favicon')
            if (favicon) {
              favicon.href = './ArisFavicon.svg'
            }
            const title = document.getElementById('title')
            if (title) {
              title.innerText = 'Aris Investing'
            }
          }
          setLogoApiLoading(false)
        }
      })
      .catch(error => {
        const favicon = document.getElementById('favicon')
        if (favicon) {
          favicon.href = './ArisFavicon.svg'
        }
        const title = document.getElementById('title')
        if (title) {
          title.innerText = 'Aris Investing'
        }
        setLogoApiLoading(false)
        setLogoDetailsFailError('Failed to login. Please contact administration')
        setHasSponsorLogoRes(false)
        Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
      }).finally(() => setLogoApiLoading(false))
  }

  const handleOptVerification = async (e) => {
    e.preventDefault()
    setIsButtonDisabled(true)
    const signInDetails = await SignInMfa({ user: props?.user, action: props?.action, value: fullOtp })
    if (signInDetails?.type === 'SUCCESS') {
      setIsNewUser(false) // set isNewUser false before assigning latest user.
      setSignInDetails(signInDetails)
      if (props?.action !== 'MFA_LOGIN') {
        fetchUserGroups()
        getLogoDetailsApi(props?.user?.username)
        fetchACLAccessApi(props?.user?.username, 2)
      }
    } else {
      showError(signInDetails?.value?.message)
      setIsButtonDisabled(false)
    }
  }

  const fetchUserGroups = async () => {
    try {
      const getUserGroups = `${process.env.REACT_APP_BASE_URI_USER}user/v1/user-groups/${props?.user?.username}`

      const response = await fetch(getUserGroups, {
        method: 'GET',
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY_USER,
          'Content-Type': 'application/json'
        }
      })

      const resData = await response.json()
      if (resData) {
        // sort user groups precedence vise and set the higher precedence group as current User Group
        const sortedGroup = resData?.data?.sort((a, b) => a.precedence - b.precedence)
        setUserGroup(sortedGroup[0]?.groupName)
        setUserApiLoading(false)
        storeAllUserGroups(resData?.data)
      }
    } catch (error) {
      setUserApiLoading(false)
      setUserGroup('')
      setUserGroupsFailError('Failed to login. Please contact administration')
      Sentry.captureException(error.response?.data?.errorInfo?.userMessage || error)
    } finally {
      setUserApiLoading(false)
    }
  }

  useEffect(() => {
    if (props?.user?.username && props?.action === 'MFA_LOGIN') {
      fetchUserGroups()
      getLogoDetailsApi(props?.user?.username)
      fetchACLAccessApi(props?.user?.username, 2)
    }
  }, [props?.user?.username])

  return (
    <Stack>
      <Grid container mt={props.action === 'MFA_LOGIN' ? 4 : 2}>
        <Grid xs={12} sm={12}>
          <Typography
            gutterBottom
            sx={{
              fontFamily: 'Open Sans',
              color: '#002A59',
              fontWeight: '600',
              marginBottom: '0'
            }}
          >
            Enter 6 digit Verification Code
          </Typography>
        </Grid>
        <form onSubmit={handleOptVerification}>
          <Grid xs={12} sm={12} mt={1} mb={4}>
            <MuiOtpInput
              value={otp}
              autoFocus
              onChange={handleChange}
              length={6}
              onComplete={handleComplete}
              TextFieldsProps={{ placeholder: '-' }}
              sx={{
                gap: {
                  xs: '5px',
                  sm: '12px'
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none'
                },
                '& .MuiOutlinedInput-root': {
                  borderRadius: 2,
                  border: '1.5px solid #e3e4e8',
                  height: {
                    xs: '35px',
                    sm: '40px'
                  }
                },
                '& .MuiInputBase-input': {
                  padding: '8px'
                },
                '& .MuiOtpInput-TextField': {
                  borderRadius: 2
                }
              }}
            />
          </Grid>
          <Grid xs={12} sm={12}>
            <Box sx={{ margin: 'auto' }} textAlign='center'>
              {isButtonDisabled
                ? (<LoadingButton
                  loading
                  loadingPosition='start'
                  startIcon={<SaveIcon />}
                  variant='outlined'
                  fullWidth
                >
                  Verify
                </LoadingButton>)
                : <Button
                  fullWidth
                  variant='contained'
                  type='submit'
                  disabled={isVerifyDisabled || isButtonDisabled}
                  size='large'
                >
                  {props?.user?.challengeName === 'SOFTWARE_TOKEN_MFA' ? 'Verify' : 'Submit'}
                </Button>}
            </Box>
          </Grid>
        </form>
      </Grid>
    </Stack>
  )
}
